export default function GenericBlueButton({href, title, className}: GenericButtonProps) {
	return (
		<a href={href}
		   className={`rounded-full bg-formica_dark text-white font-medium min-w-max px-5 pt-[5px] xl:pt-[8px] pb-[10px] text-lg h-10 xl:h-11  hover:bg-formica_dark/90 ${className}`}>
			{title}
		</a>
	)
}

interface GenericButtonProps {
	href?: string,
	title?: string
	className?: string
}