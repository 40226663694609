import Icon from "@/components/icon/Icon";
import {IconProps} from "react-icomoon";

export function HeaderButton({title, icon, href, showMegaMenu, className, iconProps, mode}: HeaderButtonProps) {
	return (
		<a href={href} className={`${mode=="dark" ? "text-text_dark" : "text-white"} flex items-center font-normal text-lg min-w-max h-12 xl:h-20 ${className}`}>
			{title}
			{
				icon && <Icon icon={icon} width={20} {...iconProps}/>
			}
		</a>
	)
}

interface HeaderButtonProps {
	title?: string,
	icon?: string,
	href?: string,
	showMegaMenu?: boolean,
	className?: string
	iconProps?: Partial<IconProps>,
	mode?: "light" | "dark"
}