"use client"
import {ReactElement} from "react";
import MegaMenuItem, {MegaMenuItemProps} from "@/components/mega-menu/MegaMenuItem";
import classNames from "classnames";

export default function MegaMenuWrapper({title, containerClassName, items, children}: MegaMenuWrapperProps) {
	return (
		<div className={"group relative flex"}>
			{children}
			{
				<div
					onMouseEnter={(e) => {
						e.currentTarget.classList.remove("group-hover:scale-100")
					}}
					onMouseLeave={(e) => {
						e.currentTarget.classList.add("group-hover:scale-100")
					}}
					className={"group/bg absolute w-[400vw] h-[100vh] -ml-[100vw] z-10 top-20 rounded-b-xl scale-0 transition-all bg-navbar_bg group-hover:scale-100"}/>}
			<div
				className={classNames("absolute z-20 top-20 rounded-b-xl scale-0 transition-all bg-white group-hover:scale-100 gap-1 grid grid-cols-2 min-w-max p-10", containerClassName)}>
				{title && <div className={"font-medium text-formica text-lg px-3 w-full col-span-full"}>
					{title}
				</div>}
				{items.map((item, index) => MegaMenuItem({...item}))}
			</div>
		</div>
	)
}

interface MegaMenuWrapperProps {
	title?: string
	items: Array<MegaMenuItemProps>
	children: ReactElement,
	containerClassName?: string,
	mode?: "light" | "dark"
}
