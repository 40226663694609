export default function GenericWhiteButton({href, title, className}: GenericButtonProps) {
	return (
		<a href={href}
		   className={`rounded-full bg-white text-center text-black font-normal min-w-max px-5 pt-[8px] pb-[10px] text-base md:text-lg h-10 md:h-11 shadow-button hover:shadow-none ${className}`}>
			{title}
		</a>
	)
}

interface GenericButtonProps {
	href?: string,
	title?: string
	className?: string
}