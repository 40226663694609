import Image from "next/image";
import Icon from "@/components/icon/Icon";
import classNames from "classnames";
import { useTranslations } from "next-intl";
import Cookies from 'js-cookie';

export default function LanguageItem({
	                                     image,
	                                     title,
	                                     href,
	                                     id,
	                                     onSelect,
	                                     disableArrowIcon,
	                                     containerClassName
                                     }: LanguageItemProps) {
	const nextLocale = Cookies.get("NEXT_LOCALE") || "en";
	const selected = nextLocale === id;

	const onClick = () => {
		if (onSelect) {
			onSelect(id);
		}
		if(id === nextLocale) return;
		Cookies.set("NEXT_LOCALE", id);
		window.location.reload();
	};

	return (
		<a
			onClick={onClick}
			className={classNames("flex flex-row min-w-[154px] py-3 px-3 cursor-pointer gap-3 items-center justify-between hover:bg-whitesmoke rounded-lg", containerClassName)}
			href={href} key={title}>
			<div className={"flex flex-row items-center gap-x-3"}>
				{image && <Image src={image} alt={title || href || "Image"} width={28} height={28} />}
				{title && <p className={"font-normal text-text_dark_blue text-base"}>
					{title}
				</p>}
			</div>
			{selected && <Icon icon={"check"} width={16} className={"text-text_dark_blue"} />}
		</a>
	);
}

export interface LanguageItemProps {
	image?: string;
	id?: string;
	activeImage?: string;
	title?: string;
	href?: string;
	disableArrowIcon?: boolean;
	items?: Array<LanguageItemProps>;
	selectedItem?: string | null;
	onSelect?(id: string | null): void;
	containerClassName?: string;
}
