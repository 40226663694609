import Image from "next/image";
import Icon from "@/components/icon/Icon";
import classNames from "classnames";
import {LanguageItemProps} from "@/components/mega-menu/language/LanguageItem";
import {useTranslations} from "next-intl";
import Cookies from "js-cookie";

export default function MobileLanguageItem({
	                                           image,
	                                           activeImage,
	                                           title,
	                                           href,
	                                           items,
	                                           disableArrowIcon,
	                                           selectedItem,
	                                           onSelect,
	                                           id,
	                                           containerClassName
                                           }: LanguageItemProps) {

	const nextLocale = Cookies.get("NEXT_LOCALE") || "en";
	const selected = nextLocale === id;

	const onClick = async () => {
		if (selectedItem == title) {
			onSelect && onSelect(id)
			return
		}
		if(id === nextLocale) return;
		Cookies.set("NEXT_LOCALE", id);
		window.location.reload();
		onSelect && title && onSelect(title)
	}
	return (
		<div key={title}>
			<a
				className={classNames("flex flex-row w-full xl:min-w-52 py-3 pl-3 xl:mr-14 pr-5 cursor-pointer gap-3 min-w-max items-center justify-between hover:bg-whitesmoke rounded-lg border-b-['#F2F2F2'] border-b-1", containerClassName)}
				href={href} key={title} onClick={onClick}>
				<div className={"flex flex-row items-center gap-x-3"}>
					{image && (!activeImage || selectedItem != title) &&
						<Image src={image} alt={title || href || "Image"} width={28} height={28}/>}
					{activeImage && selectedItem == title &&
						<Image src={activeImage} alt={title || href || "Image"} width={28} height={28}/>}
					{title && <p className={"font-normal text-text_dark_blue text-sm"}>
						{title}
					</p>}
				</div>
				{selected && <Icon icon={"check"} width={16} className={"text-text_dark_blue"}/>}
			</a>
			{selectedItem == title && items &&
				<div className={"flex flex-col pl-14 pr-3 text-text_light"}>
					{items.map((item, index) =>
						<a key={index}
						   className={classNames("flex flex-row gap-x-2 border-b-['#F2F2F2'] border-b-1", index % 2 == 0 ? "order-1" : "order-2")}
						   href={item.href}>
							{item.image && <Icon icon={item.image} width={12}/>}
							{item.title && <p className={"font-normal text-xs py-3"}>
								{item.title}
							</p>}
						</a>)}
				</div>}
		</div>
	)
}
