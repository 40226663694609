"use client"
import GenericHeader, {GenericHeaderProps} from "@/components/generic/GenericHeader";
import {useEffect, useState} from "react";
import GenericMobileHeader from "@/components/generic/GenericMobileHeader";

export default function HeaderContainer({containerClassName, mode}: GenericHeaderProps) {
	const [windowWidth, setWindowWidth] = useState(1024);

	useEffect(() => {
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
		};
		setWindowWidth(window.innerWidth)
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);
	return (
		windowWidth >= 1280 ?
			<GenericHeader containerClassName={containerClassName} mode={mode}/> :
			<GenericMobileHeader containerClassName={containerClassName} mode={mode}/>

	)

}