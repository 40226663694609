import Image from "next/image";
import Icon from "@/components/icon/Icon";
import classNames from "classnames";

export default function MegaMenuItem({image, title, href, disableArrowIcon, containerClassName}: MegaMenuItemProps) {

	return (
		<a
			className={classNames("flex flex-row min-w-[278px] py-3 px-3 cursor-pointer gap-3 items-center justify-between hover:bg-whitesmoke rounded-lg", containerClassName)}
			href={href} key={title}>
			<div className={"flex flex-row items-center gap-x-3"}>
				{image && <Image src={image} alt={title || href || "Image"} width={48} height={48}/>}
				{title && <p className={"font-normal text-text_dark_blue text-base"}>
					{title}
				</p>}
			</div>
			{!disableArrowIcon && <Icon icon={"down_arrow"} width={20} className={"-rotate-90 text-text_dark_blue"}/>}
		</a>
	)
}

export interface MegaMenuItemProps {
	image?: string
	activeImage?: string
	title?: string
	href?: string
	disableArrowIcon?: boolean
	items?: Array<MegaMenuItemProps>
	selectedItem?: string | null
	onSelect?(title: string | null): void
	containerClassName?: string
}
