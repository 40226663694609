import Image from "next/image";
import Icon from "@/components/icon/Icon";
import MegaMenuItem, {MegaMenuItemProps} from "@/components/mega-menu/MegaMenuItem";
import classNames from "classnames";

export default function MobileMegaMenuItem({
	                                           image,
	                                           activeImage,
	                                           title,
	                                           href,
	                                           items,
	                                           disableArrowIcon,
	                                           selectedItem,
	                                           onSelect,
	                                           containerClassName
                                           }: MegaMenuItemProps) {
	const onClick = () => {
		if (selectedItem == title) {
			onSelect && onSelect(null)
			return
		}
		onSelect && title && onSelect(title)
	}
	return (
		<div key={title}>
			<a
				className={classNames("flex flex-row w-full xl:min-w-52 py-3 pl-3 xl:mr-14 pr-5 cursor-pointer gap-3 min-w-max items-center justify-between hover:bg-whitesmoke rounded-lg", containerClassName)}
				href={href} key={title} onClick={onClick}>
				<div className={"flex flex-row items-center gap-x-3"}>
					{image && (!activeImage || selectedItem != title) &&
						<Image src={image} alt={title || href || "Image"} width={40} height={40}/>}
					{activeImage && selectedItem == title &&
						<Image src={activeImage} alt={title || href || "Image"} width={40} height={40}/>}
					{title && <p className={"font-normal text-text_dark_blue text-sm"}>
						{title}
					</p>}
				</div>
				{!disableArrowIcon && <Icon icon={"down_arrow"} width={16}
				                            className={classNames("-rotate-90 text-text_dark_blue", selectedItem == title && "rotate-0")}/>}
			</a>
			{selectedItem == title && items &&
				<div className={"flex flex-col pl-14 pr-3 text-text_light"}>
					{items.map((item, index) =>
						<a key={index} className={classNames("flex flex-row gap-x-2 border-b-['#F2F2F2'] border-b-1", index % 2 == 0 ? "order-1" : "order-2")} href={item.href}>
							{item.image && <Icon icon={item.image} width={12}/>}
							{item.title && <p className={"font-normal text-xs py-3"}>
								{item.title}
							</p>}
						</a>)}
				</div>}
		</div>
	)
}