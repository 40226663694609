"use client"
import {ReactElement, useState} from "react";
import {MegaMenuItemProps} from "@/components/mega-menu/MegaMenuItem";
import classNames from "classnames";
import MobileMegaMenuItem from "@/components/mega-menu/MobileMegaMenuItem";
import {LanguageItemProps} from "@/components/mega-menu/language/LanguageItem";
import MobileLanguageItem from "@/components/mega-menu/language/MobileLanguageItem";

export default function MobileLanguageMenuWrapper({containerClassName, items, children}: MobileMegaMenuWrapperProps) {
	const [selectedItem, setSelectedItem] = useState<string | null>(null)

	const onSelect = (title: string | null) => {
		if (title == null) return setSelectedItem(null)
		setSelectedItem(items.find(item => item.title == title)?.title || null)
	}

	const onEnter = (parentElement: HTMLElement | HTMLDivElement) => {
		if (!parentElement) return;
		for (let i = 0; i < parentElement.children.length; i++) {
			parentElement.children.item(i)?.classList.add("group-hover:scale-100");
		}
	}
	const onLeave = (parentElement: HTMLElement | HTMLDivElement) => {
		if (!parentElement) return;
		for (let i = 0; i < parentElement.children.length; i++) {
			parentElement.children.item(i)?.classList.remove("group-hover:scale-100");
		}
	}
	return (
		<div className={"group relative flex"}
		     onMouseEnter={(e) => onEnter(e.currentTarget)}
		     onTouchStart={(e) => onEnter(e.currentTarget)}>
			{children}
			{
				<div
					onMouseEnter={(e) => onLeave(e.currentTarget.parentElement || e.currentTarget)}
					className={"group/bg absolute w-[400vw] h-[100vh] -ml-[100vw] z-10 top-12 md:top-[72px] rounded-b-xl scale-0 transition-all bg-navbar_bg group-hover:scale-100"}/>}
			<div
				className={classNames("border-t-1 border-t-text_light absolute -ml-[calc(100vw-76px)] md:-ml-[calc(100vw-96px)] z-20 top-12 md:top-16 rounded-b-xl scale-0 transition-all bg-white group-hover:scale-100 gap-1 grid grid-cols-1 w-[100vw] p-5", containerClassName)}>
				{items.map((item, index) => MobileLanguageItem({...item, selectedItem, onSelect}))}
			</div>
		</div>
	)
}

interface MobileMegaMenuWrapperProps {
	items: Array<LanguageItemProps>
	children: ReactElement,
	containerClassName?: string,
	mode?: "light" | "dark"
}
